import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "인구감소를 어떻게 받아들여야 하나?",
  "slug": "data9",
  "date": "2022-03-16T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb09.png",
  "featuredImg": "../images/featured/featured09.png",
  "summary": "인구 감소에 대한 한국인의 인식은 어떤지를 들여다보았다.",
  "tags": ["한국사회", "인구감소", "사회문제"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`2019년 3월 28일 통계청은 <장래 인구 특별 추계>를 발표했다. 장래 인구 추계는 매 5년 단위로 발표하는 것으로 2021년이 통상적인 발표 시점이다. 하지만 초저출산 등 인구 구조의 변동 흐름이 급박하다고 판단해 앞당겨 통계를 내놓은 것이다. 2019년부터 사망자 수가 출생자 수보다 많아지는 데드 크로스(dead cross)가 시작될 전망이다. 이러한 인구 감소의 결과 이민자를 포함한 한국 인구는 2028년 5,194만 명으로 정점을 찍은 뒤, 2029년부터 감소할 것으로 예측된다. 현재의 출산율이 계속된다면 총인구는 2044년에 5,000만 명 벽을 뚫고 2066년 3,000만 명 대로 낮아지며, 2117년에는 2,081만 명에 그치게 된다. 생산가능인구 감소는 이미 2017년부터 시작됐으며 이 추세가 더 굳어져 2020년대에는 연평균 33만 명씩 줄어들 것으로 보인다. 이러한 인구 구조 변화와 인구 감소는 사회 전체에 영향을 미치며 위기의식을 불러일으키고 있다. 인구 감소에 대한 한국인의 인식은 어떤지를 들여다보았다.`}</p>

    <h2>{`현재 수준과 비교할 때 우리나라의 적정한 인구는?`}</h2>
    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`한국의 적정 인구 규모에 대해서는 '현재보다 증가해야 한다'는 응답이 가장 많았다. 이어서 현재 수준이 적정하다'와 '현재보다 감소해야 한다' 순이었다. 인구가 현재보다 ‘감소해야 한다는 응답은 20대(19세 포함)에서 가장 높았다. 이 연령대에서는 '현재 수준이 적정하다'는 답이 현재보다 증가해야 한다'보다 약간 높았다. 현재 수준이 적정하다'는 응답은 30대에서, '인구가 증가해야 한다'는 응답은 60세 이상(52.6%)에서 가장 높게 나타났다.`}</p>
    <h3>{`인구 감소, 한국의 위기인가?`}</h3>
    <p>{`한국인 10명 중 8~9명은 인구 감소가 사회의 위기 요인이라고 인식하고 있다. 특히 40대(87.1%), 생산기술직(90.7%), 월평균 가구소득 350만 원 이상 500만 원 미만에 속한 사람(93.0%) 중에서 위기라는 응답이 가장 많았다.   `}</p>
    <h3>{`인구 감소가 초래할 영향`}</h3>
    <p>{`인구 감소가 초래할 영향을 수도권 인구 집중, 고령자 돌봄 여건, 경제 여건, 일자리 여건, 자녀 교육 여건 면에서 각각 긍정과 부정 측면에서 질문했는데 모든 항목에서 부정적 전망이 높았다. 다만, 일자리 여건 전망에서 20대(19세 포함)는 긍정적으로 보는 전망이 49.1%로 부정적 응답 41.5%보다 높았다.   `}</p>
    <h6>{`*<경향신문>과 공공의창이 공동 기획하고 여론조사 전문 기관 리서치뷰가 2018년 12월 17~19일 전국 성인남녀 1,000명을 대상으로 시행했다. 휴대폰 ARS 방식으로 진행했다. 2만 7,086명에게 전화를 걸어 1,000명에게서 답변을 받았고(응답률 3.7%), 표본오차는 95% 신뢰수준에 ±3.1%p다. 대상자 표집은 2018년 11월 말 현재 행정안전부 발표 주민등록인구를 기준으로 성별, 연령별 지역별로 비례 할당했다. 질문 작성과 감수 과정에서 한국인구학회의 도움을 받았다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      